<template>
<div class="counteract">
    <div class="img">

  </div>
</div>
  
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .counteract {
        width: 100%;
        height: 1113px;
        flex: 1;
        .img {
            height: 100%;
            background: center center url(~@/assets/counteract/counteract1.png) no-repeat;
            background-size: 100% 100%;
        }
    }
    
    
</style>